import styled from "styled-components";

const LayoutStyled = styled.div`
  min-height: 100vh;
  .ant-sider {
    position: fixed;
    height: 100vh;
    z-index: 3;

    + .site-layout {
      margin-left: 200px;
    }

    &.ant-layout-sider-below {
      //this css show up on small screen //when the sider collapsible
      + .site-layout {
        margin-left: 0;
      }
    }
    .ant-layout-sider-children {
      overflow: auto;
    }
  }

  .menu-fixed-bottom {
    position: absolute;
    width: 100%;
    bottom: 0px;
  }
  .logo {
    max-width: 100%;
    width: 95px;
    margin: 10px 0;
    margin-left: 15px;
  }

  .agent-header {
    background-color: #fff;
    height: 35px;
    line-height: 35px;
    padding: 0px;
    padding-right: 30px;
    text-align: end;
  }

  .site-header {
    background-color: #fff;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ececec;
    .path-name {
      display: flex;
      align-items: center;
    }

    p {
      margin-bottom: 0px;
      font-size: 20px;
      line-height: 30px;
      font-weight: 700;
    }

    .back-icon-container {
      padding: 7px 10px 7px 10px;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      margin-right: 10px;
      cursor: pointer;
    }

    .back-icon {
      display: block;
    }
  }

  .store-logo {
    padding: 15px;
    width: 120px;
    max-height: 130px;
    object-fit: contain;
  }

  .logo-area {
    text-align: center;
  }

  .page-content {
    flex: 1;
    position: relative;
  }

  .footer-text {
    text-align: center;
    color: #b9b9b9;
    padding-top: 5px;
    line-height: 30px;
    font-size: 10px;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }

  .menu {
    padding-top: 15px;
    padding-bottom: 10px;
  }

  .menu-title {
    color: rgba(255, 255, 255, 0.65);
    padding-left: 24px;
    margin-bottom: 0px;
    font-weight: 700;
    line-height: 19px;
  }

  .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background-color: #001529 !important;
  }

  .submenu-item {
    padding-left: 24px !important;
  }

  .submenu .ant-menu-submenu-arrow {
    display: none !important;
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    padding-left: 16px !important;
  }

  .submenu .ant-menu-submenu-title {
    font-weight: 700;
    font-size: 12px;
  }
`;

export default LayoutStyled;
