import moment from "moment";
import get from "lodash/get";

import packageJson from "../../package.json";

export const workingTimeFormat = "HH:mm";

export const defaultWorkingTime = {
  startTime: moment("08:00", workingTimeFormat),
  endTime: moment("17:00", workingTimeFormat),
};

const defaultDayWorking = {
  shifts: [],
};

export const defaultOpenDay = {
  mon: defaultDayWorking,
  tue: defaultDayWorking,
  wed: defaultDayWorking,
  thu: defaultDayWorking,
  fri: defaultDayWorking,
  sat: defaultDayWorking,
  sun: defaultDayWorking,
};

export const SETTING_AVAILABILITY = {
  AVAILABLE: "1",
  UNAVAILABLE: "0",
};

export const merchantRole = {
  admin: "admin",
  super_admin: "super_admin",
  retail_staff: "retail_staff",
  finance: "finance",
  operator: "operator",
  agent: "agent",
};

export const VERSION = get(packageJson, "version", "undefined");
