import get from "lodash/get";

export const authSelector = (state) => state.auth;

export const isLoggedInSelector = (state) => get(state, "auth.isLoggedIn");

export const isMerchantSelector = (state) => get(state, "auth.isMerchantSelected");

export const initPageFinishSelector = (state) => get(state, "auth.initPageFinish");

export const userRoleSelector = (state) => get(state, "auth.user.role");

export const userSelector = (state) => get(state, "auth.user");

export const userEmailSelector = (state) => get(state, "auth.user.email");
