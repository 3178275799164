import en from "./locales/en";
import my from "./locales/my";
import errorEn from "./locales/en/error";
import errorMy from "./locales/my/error";

export const DEFAULT_LANGUAGE = "en";

// Language options
export const LANGUAGES = [
  {
    value: "en",
    label: "English",
  },
  {
    value: "my",
    label: "Bahasa Malaysia",
  },
];

// Translations for i18n
export const RESOURCES = {
  en: {
    translation: en,
    error: errorEn,
  },
  my: {
    translation: my,
    error: errorMy,
  },
};
