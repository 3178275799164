import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAPILoading from "hooks/useAPILoading";
import authAction from "reduxStore/Auth/actions";
import { userSelector } from "reduxStore/Auth/selectors";
import { selectMerchant } from "APIService/authorization";
import { Modal, Button, Card } from "antd";
import { getDistance } from "geolib";
import PageLoading from "./PageLoading";

const title = (userName) => (
  <div>
    <b>Change kiosk location</b>
    <div style={{ color: "#888888", fontSize: "13px" }}>
      You're about to change the location. Please choose your kiosk location below
    </div>
  </div>
);

function ModalChangeMerchant({ onOpen, onClose }) {
  const dispatch = useDispatch();
  const merchants = useMemo(() => JSON.parse(localStorage.getItem("merchantsAvailable")), []);
  const [callSelectMerchant, loadingSelectMerchant] = useAPILoading(selectMerchant);
  const { name: userName } = useSelector(userSelector);

  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState(0);
  const [userLocation, setUserLocation] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleCardClick = useCallback(
    (merchantId) => {
      setSelectedMerchant(merchantId);
    },
    [setSelectedMerchant]
  );

  useEffect(() => {
    getUserLocation();
  }, []);

  const getUserLocation = () => {
    if ("geolocation" in navigator) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation(position.coords);
          setLoading(false);
        },
        (error) => {},
        { enableHighAccuracy: false, timeout: 5000, maximumAge: 0 }
      );
    }
  };

  const merchantList = useMemo(() => {
    return merchants.map((merchant) => {
      if ("geolocation" in navigator) {
        if (userLocation && merchant.latitude && merchant.longitude) {
          const { latitude, longitude } = userLocation;
          const distance = getDistance(
            { latitude, longitude },
            { latitude: merchant.latitude, longitude: merchant.longitude }
          );
          return { ...merchant, distance: (distance / 1000).toFixed(2) };
        }
        return { ...merchant, distance: 0 };
      }
      return { ...merchant, distance: 0 };
    });
  }, [merchants, userLocation]);

  const handleSubmit = async () => {
    // setLoading(true);
    await callSelectMerchant(selectedMerchant).then((res) => {
      const { data = {} } = res;
      if (data.token) {
        const actionPayload = authAction.loginWithMerchant({
          userInfo: data.userInfo,
          token: data.token,
          merchantInfo: data.merchantInfo,
        });
        dispatch(actionPayload);
      }
    });
    // setIsModalOpen(false);
    onClose();
    const redirectPath = "/"; 
    window.location.href = redirectPath;

    // setTimeout(() => {
    //     setLoading(false);
    // }, 500);
  };

  return (
    <Modal
      title={title(userName)}
      visible={onOpen}
      onCancel={onClose}
      onOk={handleSubmit}
      bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}
      closable={false}
      width={"50%"}
      cancelButtonProps={{ style: { display: "none" } }}
      footer={[
        <Button
          key="submit"
          onClick={onClose}
        >
          Cancel
        </Button>,
        <Button
        key="submit"
        disabled={selectedMerchant === 0 ? true : false}
        type="primary"
        loading={loadingSelectMerchant}
        onClick={handleSubmit}
      >
        Confirm
      </Button>,
      ]}
    >
      {loading ? (
        <PageLoading />
      ) : (
        <>
          {merchantList.map((merchant) => {
            return (
              <Card
                hoverable
                style={{ border: selectedMerchant === merchant.id && "2px solid #1890ff", marginBottom: "10px" }}
                onClick={() => handleCardClick(merchant.id)}
              >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <div>
                      <b>{merchant.store_name}</b>
                    </div>
                    <div style={{ color: "#888888", fontSize: "13px" }}>{merchant.address}</div>
                    <div>
                      {merchant.isOpened ? (
                        <span style={{ color: "#008000" }}>Open </span>
                      ) : (
                        <span style={{ color: "#ff0000" }}>Closed </span>
                      )}
                      ~ Close 10pm
                    </div>
                  </div>
                  <div>
                    <b>{merchant.distance}KM</b>
                  </div>
                </div>
              </Card>
            );
          })}
        </>
      )}
    </Modal>
  );
}

export default ModalChangeMerchant;
