import { defaultOpenDay, SETTING_AVAILABILITY } from "variables";

export const formatStoreData = (storeSetting) => {
  const newStoreData = { ...storeSetting };

  if (newStoreData.open_day === null) {
    newStoreData.open_day = defaultOpenDay;
  }

  if (newStoreData.is_available === SETTING_AVAILABILITY.AVAILABLE) {
    newStoreData.is_available = true;
  }

  if (newStoreData.is_available === SETTING_AVAILABILITY.UNAVAILABLE) {
    newStoreData.is_available = false;
  }

  return newStoreData;
};

export const parseStoreData = (storeSetting) => {
  const newStoreData = { ...storeSetting };

  if (newStoreData.is_available === true) {
    newStoreData.is_available = SETTING_AVAILABILITY.AVAILABLE;
  }

  if (newStoreData.is_available === false) {
    newStoreData.is_available = SETTING_AVAILABILITY.UNAVAILABLE;
  }

  return newStoreData;
};
