import { loginByToken, logout } from "APIService/authorization";
import storeAction from "../store/actions";

export const actionType = {
  LOGIN: "AUTH.LOGIN",
  LOGOUT: "AUTH.LOGOUT",
  INIT_PAGE_FINISH: "AUTH.INIT_PAGE_FINISH",
  LOGIN_MERCHANT_SELECTED: "AUTH.LOGIN.MERCHANT.SELECTED",
};

const actions = {
  login:
    ({ userInfo, token, merchantInfo } = {}) =>
    (dispatch) => {
      localStorage.setItem("JWT", token);
      localStorage.setItem("merchantsAvailable", JSON.stringify(merchantInfo));

      dispatch(storeAction.initStore(merchantInfo));
      dispatch({
        type: actionType.LOGIN,
        user: userInfo,
      });
    },
  loginWithMerchant:
    ({ userInfo, token, merchantInfo } = {}) =>
    (dispatch) => {
      localStorage.setItem("JWT", token);
      localStorage.setItem("merchantsAvailableSelected", JSON.stringify(merchantInfo));

      dispatch(storeAction.initStore(merchantInfo));
      dispatch({
        type: actionType.LOGIN_MERCHANT_SELECTED,
        user: userInfo,
      });
    },

  loginByToken: () => (dispatch) => {
    const token = localStorage.getItem("JWT");
    if (token) {
      loginByToken(token)
        .then((res) => {
          const { data = {} } = res;
          const { userInfo, merchantInfo, isMerchantSelected } = data;
          if (userInfo) {
            dispatch(storeAction.initStore(merchantInfo));
            dispatch({
              type: isMerchantSelected ? actionType.LOGIN_MERCHANT_SELECTED : actionType.LOGIN,
              user: userInfo,
            });
          } else {
            throw Error("token expired");
          }
        })
        .catch(() => {
          //token expired
          localStorage.removeItem("JWT");
          dispatch({ type: actionType.INIT_PAGE_FINISH });
        });
    } else {
      dispatch({ type: actionType.INIT_PAGE_FINISH });
    }
  },
  logOutWithoutAPI: () => {
    localStorage.removeItem("JWT");
    localStorage.removeItem("merchantsAvailable");
    return {
      type: actionType.LOGOUT,
    };
  },
  logout: () => (dispatch) => {
    logout()
      .then(() => {
        localStorage.removeItem("JWT");
        localStorage.removeItem("merchantsAvailable");
      })
      .catch(() => {
        localStorage.removeItem("JWT");
        localStorage.removeItem("merchantsAvailable");
      });
    dispatch({
      type: actionType.LOGOUT,
    });
    dispatch(storeAction.clearStore());
  },
};

export default actions;
