import "antd/dist/antd.css";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { initPageFinishSelector } from "reduxStore/Auth/selectors";
import authAction from "reduxStore/Auth/actions";
import Routes from "../Routes";
import PageLoading from "components/PageLoading";

export default function App() {
  const initPageFinish = useSelector(initPageFinishSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authAction.loginByToken());
  }, [dispatch]);

  if (!initPageFinish) return <PageLoading fullScreen />;

  return <Routes />;
}
