import axios from "./axios";
import {
  LOGIN,
  VERIFY_EMAIL_CODE,
  LOGIN_BY_TOKEN,
  CHANGE_PASSWORD,
  RESET_PASSWORD,
  LOGOUT,
  SELECT_MERCHANT,
} from "./APILinks";

export const login = (email, password) => axios.post(LOGIN, { email: email, password });

export const verifyEmailCode = (email, verifyCode) =>
  axios.post(VERIFY_EMAIL_CODE, {
    email: email,
    verifyEmailCode: verifyCode,
  });

export const selectMerchant = (merchantId) =>
  axios.post(SELECT_MERCHANT, {
    merchant_id: merchantId,
  });

export const loginByToken = (token) => axios.post(LOGIN_BY_TOKEN);

export const forgetPassword = (email) => axios.post(RESET_PASSWORD, { email });

export const resetPassword = (token, newPassword) =>
  axios.put(
    RESET_PASSWORD,

    { newPassword },

    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const changePassword = ({ newPassword, currentPassword } = {}) =>
  axios.put(CHANGE_PASSWORD, { newPassword, currentPassword });

export const logout = () => axios.post(LOGOUT);
