const en = {
  email: {
    required: "Please input your email",
    valid: "Invalid email address",
  },
  password: {
    required: "Please input your password",
    min: "Requires more than 6 characters",
  },
  phone: {
    required: "Please input your phone number",
    pattern: "No Space or Special Characters Allowed",
  },
  name: {
    required: "Full name is required",
  },
  addressRequired: "Please input store address",
  storeNameRequired: "Please input store name",
  miniumAmountRequired: "Please input your minimum amount required",
  latitudeRequired: "Please input latitude",
  longitudeRequired: "Please input longitude",
  workingTimeInvalid: "Start time should be before the end time",
  digit: "Enter the code we just sent to your email",
  updateSettingsFailed: "Update settings failed",
  emptyRequestHelp: "Please enter something",

  INVALID_EMAIL: "Invalid email address",
  INVALID_EMAIL_OR_PASSWORD: "Invalid email or password",
  INVALID_VERIFY_CODE: "Invalid verification code",
  CAN_NOT_FIND_STORE: "Can't find location",
  PLEASE_INPUT_CORRECT_DATA: "Please input correct data",
  INVALID_PASSWORD: "Invalid password",

  fullNameRequired: "Full name is required",
  EMAIL_ADDRESS_EXISTS: "Email address exists", //api error when create admin account
  haveBeenLoggedOut: "You have been logged out",
  dateRange: "Date range is required",
  updatedFeeAndRecalculated:
    "Service Fee Information was updated by admins. We recalculated fees based on the new information.",
  coinAmountLowerThanMinimum: "Coin amount is lower than the minimum",
};

export default en;
