const my = {
  email: {
    required: "Sila masukkan emel anda",
    valid: "Alamat emel tidak sah",
  },
  password: {
    required: "Sila masukkan kata laluan anda",
    min: "Perlu lebih daripada 6 aksara",
  },
  addressRequired: "Sila masukkan alamat lokasi",
  storeNameRequired: "Sila masukkan nama lokasi",
  miniumAmountRequired: "Sila masukkan amaun minima yang diperlukan",
  latitudeRequired: "Sila masukkan latitud lokasi",
  longitudeRequired: "Sila masukkan longtitud lokasi",
  workingTimeInvalid: "Masa buka mesti sebelum masa tutup",
  digit: "Sila masukkan nombor sahaja",
  updateSettingsFailed: "Gagal mengemas kini tetapan",
  emptyRequestHelp: "Sila masukkan sesuatu",

  INVALID_EMAIL: "Alamat emel tidak sah",
  INVALID_EMAIL_OR_PASSWORD: "Alamat emel atau kata laluan tidak sah",
  INVALID_VERIFY_CODE: "Kod penentusahan tidak sah",
  CAN_NOT_FIND_STORE: "Lokasi tidak dapat dikesan",
  PLEASE_INPUT_CORRECT_DATA: "Sila masukkan data yang betul",
  // INVALID_PASSWORD: 'Invalid password',

  fullNameRequired: "Nama penuh diperlukan",
  EMAIL_ADDRESS_EXISTS: "Alamat emel sudah wujud", //api error when create admin account
  updatedFeeAndRecalculated:
    "Caj perkhidmatan dikemaskinikan oleh Pengurus. Caj telah dikira semula berdasarkan infiormasi terkini",
  coinAmountLowerThanMinimum: "Jumlah syiling kurang dari had minimum",
};

export default my;
