import axios from "./axios";
import { GET_CREDIT } from "./APILinks";

export const fetchCurrentCredit = async () => {
  try {
    return axios.get(GET_CREDIT);
  } catch (e) {
    console.log(e);
  }
};
